/* .card {
  width: 90%;
  height: 80%;
  margin: auto;
  min-height: 512px;
  box-shadow: 0px 35px 70px 0px rgba(196, 255, 125, 0.65), 0px 30px 40px -10px rgba(100, 255, 239, 0.55);
  background: linear-gradient(180deg, rgb(138, 241, 74) 0%, rgb(12, 239, 148) 100%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
} */

.body_text{
    padding-left: 15%;
    padding-right: 15%;
}

.card_top {
    margin-top: 1em;
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /* width: 90%; */
    /* height: 80%; */
    /* min-height: 512px; */
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 35px 70px 0px rgba(65, 73, 56, 0.65), 0px 30px 40px -10px rgba(45, 56, 55, 0.329);
    background-image: url(./assets/background.jpg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    
}

.content {
    flex: 1 1 auto;
    /* min-height: 256px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-left: auto;
    padding-right: auto; */
    max-width: 1200px;
    margin: 0 auto;
}

.mission {
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 90%;
    box-shadow: 0px 35px 70px 0px rgba(196, 255, 125, 0.65), 0px 30px 40px -10px rgba(100, 255, 239, 0.55);
    background-size: 400% 400%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgb(138, 241, 74) 25%, rgb(30, 141, 128) 75%);
    animation: bgGradient 10s ease infinite;
    background-size: 150% 150%;
    max-width: 1300px;
}


.card {
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 90%;
    /* height: 80%; */
    /* min-height: 512px; */
    box-shadow: 0px 35px 70px 0px rgba(74, 141, 241, 0.65), 0px 30px 40px -10px rgba(100, 255, 239, 0.55);
    background-size: 400% 400%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgb(55, 103, 176) 25%, rgb(61, 100, 96) 75%);
    background-size: 150% 150%;
    max-width: 1300px;
}

.platform {
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 90%;
    /* height: 80%; */
    /* min-height: 512px; */
    box-shadow: 0px 35px 70px 0px rgba(64, 79, 114, 0.65), 0px 30px 40px -10px rgba(121, 129, 169, 0.55);
    background-size: 400% 400%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgb(64, 79, 114) 25%, rgb(105, 117, 180) 75%);
    background-size: 150% 150%;
    max-width: 1300px;
}

.impact {
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 90%;
    /* height: 80%; */
    /* min-height: 512px; */
    box-shadow: 0px 35px 70px 0px rgba(41, 159, 47, 0.65), 0px 30px 40px -10px rgba(4, 66, 7, 0.55);
    background-size: 400% 400%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgb(41, 159, 47) 25%, rgb(189, 201, 76) 75%);
    background-size: 150% 150%;
    max-width: 1300px;
}

@keyframes bgGradient {
    0% {
        background-position: 10% 0%
    }

    50% {
        background-position: 91% 100%
    }

    100% {
        background-position: 10% 0%
    }
}

/* The rest of your CSS styles remain the same */

/* 
@media screen and (max-width: 512px) {
    .card {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
    }
} */

.header {
    display: flex;
    padding: 2em;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.logo {
    font-weight: bold;
    font-size: 2em;
    transition: opacity 0.05s ease;

}

.logo:hover {
    opacity: 0.75;
}

.social {
    display: flex;
}

.social a {
    display: inline-block;
    margin-right: 12px;
    transition: opacity 0.05s ease;
}

.social a:last-child {
    margin-right: 0;
}

.social a:hover {
    opacity: 0.75;
}

.social .icon {
    width: 18px;
    fill: #fff;
}



.content .title-holder {
    color: #fff;
    text-align: center;
    /* margin-bottom: 24px; */
}

.content .title-holder h1 {
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 1em;;
}

.content .title-holder h2 {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 12px;
}

.content .title-holder p {
    /* font-size: 16px; */
    line-height: 28px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
    .content .title-holder {
        max-width: 95%;
    }
}

.content .cta {
    min-width: 64px;
    padding: 16px 24px;
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    transform: none;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
}

.content .cta:hover {
    transform: translateY(-1px);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

.footer {
    display: flex;
    flex-direction: row;
    padding: 2em;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

a.underlined:hover {
    border-bottom: 1px dotted #fff;
}

p {
    padding-bottom: 1em;
    font-size: 1em;
    max-width: 800px;
    margin: 0 auto;
}

td {
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
}

tr{
    margin: auto;
    justify-content: center;
    max-width: 100%;
}

.center {
    /* display: block;
    justify-content: center;
    width: 80%; */
    margin-left: auto;
    margin-right: auto;
    
    /* border-collapse: collapse; */
}

.table_wrapper{
    padding-bottom: 2em;
}

sup{
    font-size: 0.7em;
    vertical-align: super;
}

.concept-render {
    width: 75%;
}

ul {
    text-align: left;
    padding-bottom: 2em;
    padding-left: 10em;
    padding-right: 10em;
    list-style: circle;
}

.concept-flow-diagram{
    width: 70%;
}

.payloadImage {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    display:flex;
    width: 60%;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    text-shadow: 0px 0px 0.1em #000000;
}

.payloadSpectral{
    background-image: url('./assets/altum-pt-side.png');

}

.payloadDG10p{
    background-image: url('./assets/DG10p.png');
}

.payloadSony{
    background-image: url('./assets/sonya7r4.png');
}

.applicationBiodiversity{
    background-image: url('./assets/biodiversity.jpg');
}

.applicationGasMonitoring{
    background-image: url('./assets/gas_monitoring.jpg');
}

.applicationNaturalDisaster{
    background-image: url('./assets/natural_disaster.jpg');
}

.applicationCoastal{
    background-image: url('./assets/coastal.jpg');
}

.payloadGimbal{
    background-image: url('./assets/R10M.png');
}

.payloadsds30{
    background-image: url('./assets/SPS30.png');
}

.payloadContainer{
    /* float: left; */
    /* width: 100%; */
    display: flex;
    padding-bottom: 0em;
    height: 13em;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    margin: 0 auto;
    margin-bottom: 2em;
    padding: 1em;
    max-width: 700px;


}

.payloadText{
    float: left;
    /* display:block; */
    position: relative;
    width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;

}

.boxrowcontainer{
    /* float: left; */
    width: 100%;
    display: flex;
    padding-bottom: 0em;
    /* padding: 1em; */
}

.boxrowtext{
    float: left;
    /* display:block; */
    position: relative;
    width: 95%;
    justify-content: left;
    display: block;
    padding: 1em;
    box-shadow: 0px 20px 20px 0px rgba(65, 73, 56, 0.65), 0px 30px 40px -5px rgba(45, 56, 55, 0.329);
    background-color: rgba(0, 0, 139, 0.616);
    margin: 0.5em;
    border-radius: 0.5em;

}

@media screen and (max-width: 670px) {
    .card_top {
        background-size: auto 100%;
        padding-top: 15%;
    }

    .content {
        margin: 0 auto;
    }

    td {
        padding-left: 0;
        padding-right: 0;
    }

    .concept-render {
        width: 100%;
    }
    .concept-flow-diagram{
        width: 100%;
    }

    .payloadContainer{
        display: block;
        float:inline-start;
        width: 100%;
        height: 25em;
    
    }

    .payloadText{
        display: block;
        float:inline-start;
        width: 100%;
    }

    .payloadImage{
        display: block;
        float:inline-start;
        width: 100%;
        background-size: auto 100%;
        padding: 6em;
    }

    .boxrowcontainer{
        display: block;
    }
    
    .body_text{
        padding-left: 0%;
        padding-right: 0%;
    }

}

@media screen and (max-width: 1200px) and (min-width:670px) {
    .card_top {
        background-size: 100% auto;
    }

    .content {
        margin: 0 auto;
    }

    td {
        padding-left: 10%;
        padding-right: 10%;
    }

    .concept-render {
        width: 75%;
    }

    .concept-flow-diagram{
        width: 70%;
    }

    .payloadContainer{
        height: 20em;
    }

    .body_text{
        padding-left: 0%;
        padding-right: 0%;
    }
}